#scheduling .intl-tel-input-rtl input {
    padding-left: 6px !important;
    padding-right: 92px !important;
}

#scheduling .intl-tel-input-rtl .selected-dial-code {
    padding-left: 0 !important;
    padding-right: 38px !important;
}

#scheduling .intl-tel-input-rtl .iti-flag {
    right: 15px;
}